const data = {
    phone: '+7 (495) 032-41-92',
    phoneHref: 'tel:74950324192',

    email: 'event@ddxfitness.ru',
    emailHref: 'mailto:event@ddxfitness.ru',

    mainSite: 'ddxfitness.ru',
    mainSiteHref: 'https://ddxfitness.ru/?utm_source=event&utm_medium=organic&utm_campaign=fitness_go',

    vk: 'https://vk.com/ddx_fitness',
    yt: 'https://www.youtube.com/channel/UCF8MClm91HIe3k2ieXX3Scg',
    tg: 'https://t.me/ddxfitness_official',
    dzen: 'https://dzen.ru/ddx_fitness_ru',
}

export { data }
export { purpleGradientImage } from './about'
export { eventsImage, eventsMobileImage } from './events'
export { menuLinksDesktop, menuLinksMobile } from './header'
export { heroImage } from './hero'
export { locationSlides, locationSlidePreviews } from './location'
export { programEvents, orangeGradientImage, programImage, eventSchedules } from './program'